<template>
	<div class="main-contents">
		<div class="tit">
			이력서 검토
			<img src="/images/admin/list_shortcut.png" alt="목록가기" @click="$router.go(-1)"/>
		</div>
		<div class="member-name" v-if="data.mberNm"><span>{{data.mberNm}}</span> 회원님<img src="/images/admin/alert_guide.png" alt="문구"/></div>
		<div class="contents-body">
			<tec-resume ref="tecResume" :tecMberSeq="this.tecMberSeq"/>
			<div class="my-resume" v-if="data.mberNm">
				<div class="arrow_box">
					<div class="title"><img src="/images/admin/check.png" alt="체크">위 기술인재의 기술등급을 아래의 등급으로 확정 하시겠습니까?</div>
					<div class="rank">
						<p>기술등급_학력기준</p><grade-img type="SCHOOL_TECH_NCS"  :grade="data.resumeSchoolTechGradeCd"/>
						<p>기술등급_자격기준</p><grade-img type="LICENSE_TECH_NCS" :grade="data.resumeLicenseTechGradeCd"/>
					</div>
					<div class="list-title"><img src="/images/admin/point.png" alt="포인트">확정시 지급예정 포인트 <span>(지급 제외할 항목은 체크를 해제 해 주세요.)</span></div>
					<!-- 전체프로젝트 리스트 -->
					<div class="Board type3 career">
						<table class="Board_type3">
							<colgroup>
								<col width="5%">
								<col width="20%">
								<col width="40%">
								<col width="18%">
								<col width="17%">
							</colgroup>
							<tbody v-if="!pointList || pointList.length == 0"><tr><td colspan="5" class="none">지급예정 포인트가 없습니다!</td></tr></tbody>
							<template v-else>
								<thead>
									<tr>
										<th><input type="checkbox" v-model="checkAll" @change="checkPointAll"></th>
										<th>포인트 적립구분</th>
										<th>포인트 적립 상세내용</th>
										<th>적립포인트</th>
										<th>포인트 지급 확정유무</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="(item, idx) in pointList" :key="idx">
										<td class="score"><input type="checkbox" v-model="item.checkYn" @change="checkPoint(item)"/></td>
										<td class="score">{{item.pointSaveItemNm}}</td>
										<td class="score">{{item.pointSaveDtlCont || item.pointSaveItemDesc}}</td>
										<td class="score">{{item.pointAmt | number}} P</td>
										<td class="score">미확정</td>
									</tr>
								</tbody>
							</template>
						</table>
					</div>
					<div class="select_btn">
						<div class="btn_01" @click="$router.go(-1)">취소</div>
						<div class="btn_02" @click="reject()">수정요청하기</div>
						<div class="btn_03" @click="confirm()">확정</div>
					</div>
				</div>
			</div>

			<!-- 기술회원 메모 -->
			<tec-memo :tecMberSeq="tecMberSeq"/>
		</div>
	</div><!-- //main-contents -->
</template>
<script>
import tecMemo from "@/components/highpro/adm/TecMemo.vue";
import tecResume from "@/components/highpro/TecResume.vue";
import gradeImg from "@/components/highpro/GradeImg.vue";

export default {
	components: { tecMemo, tecResume, gradeImg },
	data() {
		return {
			tecMberSeq: this.$route.params.tecMberSeq,
            isMounted: false,
			pointList: [],
			checkAll: false,
        };
    },
    mounted(){
        this.isMounted = true;
		this.getPointList();
    },
	methods: {
		getPointList() {
			var saveMberSeq = this.tecMberSeq;
			var pointSaveTargetDivCd = 'T';
			var pointCfmYn = 'N';
			var pointSaveCfmDivCd = '02'
			this.$.httpPost('/api/mem/pit/getMberPoint', { pointSaveTargetDivCd, saveMberSeq, pointCfmYn, pointSaveCfmDivCd })
				.then(res => {
					this.pointList = res.data.list;
					this.checkAll = true;
					this.checkPointAll()
				}).catch(this.$.httpErrorCommon);
		},
		confirm(){
			var check = this.$refs.tecResume.checkAdminComment();
			if(check !== false && ((check == 0 ) || confirm(check + '건에 대한 수정요청 내용이 있습니다. 수정요청을 완료하고 확정처리하시겠습니까?'))){
				var tecMberSeq = this.tecMberSeq;
				var pointList = [];
				for(var i in this.pointList){
					if(this.pointList[i].checkYn){
						pointList.push(this.pointList[i]);
					}
				}
				this.$.http('/api/mem/adm/prf/confirmResume', { tecMberSeq, pointList })
					.then(() => {
						alert('기술등급 확정이 완료되었습니다.');
						this.$router.go(-1);
					}).catch(this.$.httpErrorCommon);	
			}
		},
		reject(){
			var check = this.$refs.tecResume.checkAdminComment();
			if(check !== false){
				if(check == 0){
					alert('수정 요청 내용이 없습니다. 수정 요청 내용을 작성 해주세요.');
				} else if(confirm(check + '건에 대한 수정요청 내용이 있습니다. 수정요청하시겠습니까?')){
					this.$.http('/api/mem/adm/prf/reqComment', { recvMberSeq : this.tecMberSeq, count: check })
						.then(() => {
							alert('수정요청 내용 알림이 전송되었습니다.');
							this.$router.go(-1);
						}).catch(this.$.httpErrorCommon);	
				}
			}
		},
		checkPointAll(){
			for(var i in this.pointList){
				this.pointList[i].checkYn = this.checkAll;
			}
		},
		checkPoint(item){
			//console.log('checkPoint', item);
			if(item.checkYn) {
				var checkAll = true;
				for(var i in this.pointList){
					if(!this.pointList[i].checkYn){
						checkAll = false;
						break;
					}
				}
				this.checkAll = checkAll;
			} else {
				this.checkAll = false;
			}
		}
	},
	computed: {
		data(){
			//console.log('MEM910M02', 'data', this.$refs.tecResume);
			if(this.isMounted && this.$refs.tecResume && this.$refs.tecResume.data){
				return this.$refs.tecResume.data;
			} else {
				return {};
			}
		}
	}
};
</script>
